<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="60%"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span v-text="RoleDialogTitle"></span>
    </div>
    <!-- roleData： {{roleData}} -->
    <el-form
      ref="formData"
      :model="formData"
      :rules="rules"
      label-width="100px"
    >
      <el-form-item label="角色名称：" prop="name">
        <el-input
          :disabled="RoleDialogTitle === '编辑角色'"
          v-model="formData.name"
          placeholder="请输入角色名称"
        ></el-input>
      </el-form-item>
      <el-row>
        <el-col :span="12">
          <el-form-item label="角色状态：" prop="state">
            <el-radio v-model="formData.state" label="E"> 启用 </el-radio>
            <el-radio v-model="formData.state" label="N"> 停用 </el-radio>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="平台选择：" prop="systemId">
            <el-select
              style="width: 100%"
              v-model="formData.systemId"
              placeholder="请选择平台"
              :disabled="RoleDialogTitle === '编辑角色'"
            >
              <el-option
                @click.native="systemChange(item)"
                v-for="item in systemList"
                :key="item.systemCode"
                :label="item.systemName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item label="平台菜单：" prop="menuIds">
        <el-tree
          :check-strictly='true'
          v-model="formData.menuIds"
          :data="systemMenus"
          show-checkbox
          default-expand-all
          node-key="id"
          ref="tree"
          highlight-current
          :props="defaultProps"
          style="max-height: 160px; overflow-y: auto; border: 1px solid #eee"
        >
        </el-tree>
      </el-form-item>
      <el-form-item label="角色描述：">
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入角色描述"
          v-model="formData.description"
        ></el-input>
      </el-form-item>
    </el-form>
    <el-row slot="footer">
      <el-button type="primary" @click="saveRoleForm" class="dialog-btn"
        >确 定</el-button
      >
      <el-button @click="close" type="info" class="dialog-btn">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
import { getMenuTree, getMenuByRole } from "@/api/system/role";
export default {
  props: {
    show: {
      default: false,
      type: Boolean,
    },
    systemList: {
      type: Array,
      default: () => [],
    },
    roleData: {
      default: () => {
        return {};
      },
      type: Object,
    },
  },
  watch: {
    show() {
      // if (this.show) {
      //   if (this.$refs.formData) {
      //     this.$refs.formData.resetFields();
      //   }
      // }
    },
    roleData: {
      handler(val) {
        if (val) {
          this.RoleDialogTitle = "编辑角色";
          this.formData = val;
          if (val.tsystem) {
            this.$nextTick(() => {
              this.initData(val);
            });
          }
        } else {
          this.RoleDialogTitle = "新增角色";
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      RoleDialogTitle: "",
      formData: {
        state: "E",
      },
      systemLists: [
        {
          label: "控制台系统",
          value: "8c3fab1a-bfaf-4778-920e-4b99d25535f2",
          nike: "cms",
        },
        {
          label: "商户系统",
          value: "2ae2ae56-630a-4fed-bf51-552f4a7bfcf5",
          nike: "merchant",
        },
        {
          label: "教育系统",
          value: "04f66a57-47bd-4996-afe7-9e1f18a3ea40",
          nike: "learn",
        },
        {
          label: "学习系统",
          value: "112fd73f-bae6-4cdd-a8b3-de94e208d445",
          nike: "learn",
        },
      ],
      systemMenus: [],
      defaultProps: {
        children: "children",
        label: "menuName",
      },
      menuIds: [],
      rules: {
        name: [{ required: true, message: "请输入角色名称", trigger: "blur" }],
        systemId: [{ required: true, message: "请选择平台", trigger: "blur" }],
        menuIds: [
          { required: false, message: "菜单不可为空", trigger: "blur" },
        ],
      },
    };
  },
  created() {},
  mounted() {},
  methods: {
    initData(val) {
      // 获取系统下菜单树
      if (val.tsystem) {
        this.systemChange(val.tsystem);
      }
      // 设置菜单回填
      getMenuByRole({ roleId: val.id }).then((res) => {
        const menuIds = res.body.map((i) => i.menuId);
        this.menuIds = menuIds;
        this.$refs.tree.setCheckedKeys(menuIds);
      });
    },
    // 动态修改系统下菜单
    systemChange(val) {
      if (val.systemCode) {
        getMenuTree({ systemCode: val.systemCode }).then((res) => {
          this.systemMenus = res.body;
        });
      }
    },
    // 关闭
    close() {
      this.$emit("close");
    },
    saveRoleForm() {
      this.$nextTick(() => {
        this.$refs.formData.validate((valid) => {
          if (valid) {
            this.formData.menuIds = this.$refs.tree.getCheckedKeys();
            if (this.formData.id) {
              this.formData.roleId = this.formData.id;
            }
            if (this.$refs.tree.getCheckedKeys().length > 0) {
              this.formData.menuIds = this.$refs.tree.getCheckedKeys();
            } else {
              this.$message.info("平台菜单不可为空！");
              return;
            }

            if (this.roleData === null) {
              this.$emit("addRole", this.formData);
            } else {
              this.$emit("editRole", this.formData);
            }
          }
        });
      });
    },
    getCheckedNodes() {
      console.log(this.$refs.tree.getCheckedNodes());
    },
    getCheckedKeys() {
      console.log(this.$refs.tree.getCheckedKeys());
    },
    setCheckedNodes() {
      this.$refs.tree.setCheckedNodes([]);
    },
    setCheckedKeys() {
      this.$refs.tree.setCheckedKeys([]);
    },
    resetChecked() {
      this.$refs.tree.setCheckedKeys([]);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/dialog.scss";
</style>
