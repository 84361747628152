<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    @open="openDialog"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>菜单管理</span>
    </div>
    <el-tree
      ref="tree"
      :data="data"
      show-checkbox
      node-key="id"
      :check-strictly="true"
      @check-change="handleCheckChange"
      :props="defaultProps"
    ></el-tree>
    <el-row slot="footer">
      <el-button type="primary" @click="saveForm" class="dialog-btn">保 存</el-button>
      <el-button @click="close" type="info" class="dialog-btn">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
import { getMenuList, getMenuByRole } from '@/api/system/role'
export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    roleId: {
      default: '',
      type: String
    }
  },
  data () {
    return {
      data: [],
      defaultProps: {
        children: 'children',
        label: 'menuName'
      },
      seleCheck: [],
      seleArr: []
    }
  },
  methods: {
    openDialog () {
      this.seleArr = [] // 初始化数组
      this.getMenuList()
      this.getMenuByRole()
    },
    close () {
      this.$emit('close')
    },

    // 获取菜单树
    async getMenuList () {
      const res = await getMenuList({})
      this.data = res.body
    },

    async getMenuByRole () {
      const res = await getMenuByRole({
        roleId: this.roleId
      })
      res.body.map(item => {
        this.seleArr.push(item.menuId)
      })
      this.$refs.tree.setCheckedKeys([...this.seleArr])
    },

    handleCheckChange (data, checked, treeChecked) {
      let res = this.$refs.tree.getCheckedNodes()
      let arr = []
      res.forEach(item => {
        arr.push(item.id)
      })
      this.seleCheck = arr
    },

    saveForm () {
      let seleMenuData = {
        roleId: this.roleId,
        menuIds: this.seleCheck
      }
      this.$emit('editMenuByRole', { ...seleMenuData })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/style/dialog.scss";
/deep/ .el-tree-node__label {
  margin-left: 10px;
}
</style>
