var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { staticStyle: { height: "calc(100vh - 40px)", "overflow-y": "auto" } },
    [
      _c("el-form", { attrs: { inline: true, model: _vm.searchForm } }, [
        _c(
          "div",
          { staticClass: "row-bg" },
          [
            _c(
              "el-row",
              { attrs: { gutter: 24 } },
              [
                _c(
                  "el-col",
                  { attrs: { lg: 5, md: 24 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "角色名称:" } },
                      [
                        _c("el-input", {
                          staticClass: "w100",
                          attrs: { clearable: "", placeholder: "请输入" },
                          model: {
                            value: _vm.searchForm.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "name", $$v)
                            },
                            expression: "searchForm.name",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { lg: 5, md: 24 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "角色状态:" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "w100",
                            attrs: { clearable: "", placeholder: "状态" },
                            model: {
                              value: _vm.searchForm.state,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "state", $$v)
                              },
                              expression: "searchForm.state",
                            },
                          },
                          _vm._l(_vm.options, function (item) {
                            return _c("el-option", {
                              key: item.key,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "span",
                  [
                    _c("el-button", { on: { click: _vm.resetForm } }, [
                      _vm._v("重置"),
                    ]),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.getRoleByPage },
                      },
                      [_vm._v("查询")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-card",
        {
          staticStyle: { "overflow-y": "scroll", height: "calc(100% - 80px)" },
          attrs: { shadow: "never" },
        },
        [
          _c(
            "div",
            {
              staticClass: "flex",
              staticStyle: { "align-items": "center" },
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c("span", [_vm._v("角色数据")]),
              _c("div", { staticClass: "flex_1" }),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.add },
                },
                [_vm._v(" 添加 ")]
              ),
              _c("text-button", {
                attrs: { icon: "el-icon-refresh", contentTit: "刷新" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.getRoleByPage.apply(null, arguments)
                  },
                },
              }),
              _c("text-button", {
                attrs: {
                  icon: "el-icon-setting",
                  contentTit: "列设置",
                  colSetting: _vm.colSetting,
                  ispopover: true,
                  baseColSetting: _vm.baseColSetting,
                },
                on: { update: _vm.update },
              }),
            ],
            1
          ),
          _c(
            "el-table",
            {
              key: _vm.tableKey,
              attrs: {
                "default-sort": { prop: "open_date", order: "descending" },
                fit: "",
                "highlight-current-row": true,
                data: _vm.dataList,
              },
            },
            _vm._l(_vm.defaultHeader, function (item) {
              return _c("el-table-column", {
                key: item.label,
                attrs: {
                  label: item.label,
                  align: item.align ? item.align : "left",
                  "min-width": item.width ? item.width : "auto",
                  sortable: item.sortable ? item.sortable : false,
                  prop: item.prop,
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          item.label === "状态"
                            ? [
                                scope.row.state === "E"
                                  ? _c("el-tag", [_vm._v("启用")])
                                  : _vm._e(),
                                scope.row.state === "N"
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "danger" } },
                                      [_vm._v("禁用")]
                                    )
                                  : _vm._e(),
                              ]
                            : item.label === "所属平台"
                            ? [
                                scope.row.tsystem &&
                                scope.row.tsystem.systemName
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(scope.row.tsystem.systemName) +
                                          " "
                                      ),
                                    ])
                                  : _c("span", [_vm._v("--")]),
                              ]
                            : item.label === "角色名称"
                            ? [_c("span", [_vm._v(_vm._s(scope.row.name))])]
                            : item.label === "创建时间"
                            ? [
                                scope.row.createTime
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            scope.row.createTime.substring(
                                              0,
                                              19
                                            )
                                          )
                                      ),
                                    ])
                                  : _c("span", [_vm._v("--")]),
                              ]
                            : item.label === "操作"
                            ? [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { "margin-left": "8px" },
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.edit(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("管理权限 ")]
                                ),
                              ]
                            : _c("span", [
                                _vm._v(_vm._s(scope.row[item.prop])),
                              ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            }),
            1
          ),
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.pagination.currentPage,
              "page-sizes": _vm.pagination.pageSizes,
              "page-size": _vm.pagination.pageSize,
              layout: _vm.pagination.layout,
              total: _vm.pagination.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _vm.roleDialog
        ? _c("add-role", {
            attrs: {
              systemList: _vm.systemList,
              show: _vm.roleDialog,
              roleData: _vm.roleData,
            },
            on: {
              close: function ($event) {
                _vm.roleDialog = false
                _vm.roleData = null
              },
              addRole: _vm.addRole,
              editRole: _vm.editRole,
            },
          })
        : _vm._e(),
      _c("power-manage", {
        attrs: { show: _vm.powerDialog, roleId: _vm.roleId },
        on: {
          close: function ($event) {
            _vm.powerDialog = false
          },
        },
      }),
      _c("menu-manage", {
        attrs: { show: _vm.menuDialog, roleId: _vm.roleId },
        on: {
          close: function ($event) {
            _vm.menuDialog = false
          },
          editMenuByRole: _vm.editMenuByRole,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }