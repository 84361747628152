var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "close-on-click-modal": false,
        width: "60%",
        top: "7vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [
          _c("i"),
          _c("span", {
            domProps: { textContent: _vm._s(_vm.RoleDialogTitle) },
          }),
        ]
      ),
      _c(
        "el-form",
        {
          ref: "formData",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "角色名称：", prop: "name" } },
            [
              _c("el-input", {
                attrs: {
                  disabled: _vm.RoleDialogTitle === "编辑角色",
                  placeholder: "请输入角色名称",
                },
                model: {
                  value: _vm.formData.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "name", $$v)
                  },
                  expression: "formData.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "角色状态：", prop: "state" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "E" },
                          model: {
                            value: _vm.formData.state,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "state", $$v)
                            },
                            expression: "formData.state",
                          },
                        },
                        [_vm._v(" 启用 ")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "N" },
                          model: {
                            value: _vm.formData.state,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "state", $$v)
                            },
                            expression: "formData.state",
                          },
                        },
                        [_vm._v(" 停用 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "平台选择：", prop: "systemId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: "请选择平台",
                            disabled: _vm.RoleDialogTitle === "编辑角色",
                          },
                          model: {
                            value: _vm.formData.systemId,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "systemId", $$v)
                            },
                            expression: "formData.systemId",
                          },
                        },
                        _vm._l(_vm.systemList, function (item) {
                          return _c("el-option", {
                            key: item.systemCode,
                            attrs: { label: item.systemName, value: item.id },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.systemChange(item)
                              },
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "平台菜单：", prop: "menuIds" } },
            [
              _c("el-tree", {
                ref: "tree",
                staticStyle: {
                  "max-height": "160px",
                  "overflow-y": "auto",
                  border: "1px solid #eee",
                },
                attrs: {
                  "check-strictly": true,
                  data: _vm.systemMenus,
                  "show-checkbox": "",
                  "default-expand-all": "",
                  "node-key": "id",
                  "highlight-current": "",
                  props: _vm.defaultProps,
                },
                model: {
                  value: _vm.formData.menuIds,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "menuIds", $$v)
                  },
                  expression: "formData.menuIds",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "角色描述：" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: 2,
                  placeholder: "请输入角色描述",
                },
                model: {
                  value: _vm.formData.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "description", $$v)
                  },
                  expression: "formData.description",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              staticClass: "dialog-btn",
              attrs: { type: "primary" },
              on: { click: _vm.saveRoleForm },
            },
            [_vm._v("确 定")]
          ),
          _c(
            "el-button",
            {
              staticClass: "dialog-btn",
              attrs: { type: "info" },
              on: { click: _vm.close },
            },
            [_vm._v("取 消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }