<template>
  <el-row style="height: calc(100vh - 40px); overflow-y: auto">
    <!-- <span class="title">角色管理</span> -->
    <!-- <el-row class="row-bg">
      <el-button @click="add" type="info" class="tb-button" size="medium"
        >新增角色</el-button
      >
    </el-row> -->
    <el-form :inline="true" :model="searchForm">
      <div class="row-bg">
        <el-row :gutter="24">
          <el-col :lg="5" :md="24">
            <el-form-item label="角色名称:">
              <el-input
                clearable
                v-model="searchForm.name"
                placeholder="请输入"
                class="w100"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="5" :md="24">
            <el-form-item label="角色状态:">
              <el-select
                clearable
                v-model="searchForm.state"
                placeholder="状态"
                class="w100"
              >
                <el-option
                  v-for="item in options"
                  :key="item.key"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <span>
            <el-button @click="resetForm">重置</el-button>
            <el-button type="primary" @click="getRoleByPage">查询</el-button>
          </span>
        </el-row>
      </div>
    </el-form>
    <el-card
      shadow="never"
      style="overflow-y: scroll; height: calc(100% - 80px)"
    >
      <div slot="header" class="flex" style="align-items: center">
        <span>角色数据</span>
        <div class="flex_1"></div>
        <el-button type="primary" size="mini" @click="add"> 添加 </el-button>
        <text-button
          icon="el-icon-refresh"
          contentTit="刷新"
          @click.native="getRoleByPage"
        ></text-button>
        <text-button
          icon="el-icon-setting"
          contentTit="列设置"
          :colSetting="colSetting"
          @update="update"
          :ispopover="true"
          :baseColSetting="baseColSetting"
        ></text-button>
      </div>

      <el-table
        :default-sort="{ prop: 'open_date', order: 'descending' }"
        :key="tableKey"
        fit
        :highlight-current-row="true"
        :data="dataList"
      >
        <el-table-column
          v-for="item of defaultHeader"
          :key="item.label"
          :label="item.label"
          :align="item.align ? item.align : 'left'"
          :min-width="item.width ? item.width : 'auto'"
          :sortable="item.sortable ? item.sortable : false"
          :prop="item.prop"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <template v-if="item.label === '状态'">
              <el-tag v-if="scope.row.state === 'E'">启用</el-tag>
              <el-tag v-if="scope.row.state === 'N'" type="danger">禁用</el-tag>
            </template>
            <template v-else-if="item.label === '所属平台'">
              <span v-if="scope.row.tsystem && scope.row.tsystem.systemName">
                {{ scope.row.tsystem.systemName }}
              </span>
              <span v-else>--</span>
            </template>
            <template v-else-if="item.label === '角色名称'">
              <span>{{ scope.row.name }}</span>
            </template>
            <template v-else-if="item.label === '创建时间'">
              <span v-if="scope.row.createTime">
                {{ scope.row.createTime.substring(0, 19) }}</span
              >
              <span v-else>--</span>
            </template>
            <template v-else-if="item.label === '操作'">
              <el-button
                style="margin-left: 8px"
                type="text"
                @click="edit(scope.row)"
                >管理权限
              </el-button>
            </template>
            <span v-else>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.currentPage"
        :page-sizes="pagination.pageSizes"
        :page-size="pagination.pageSize"
        :layout="pagination.layout"
        :total="pagination.total"
      >
      </el-pagination>
    </el-card>
    <!-- add & edit dialog -->
    <add-role
      :systemList="systemList"
      :show="roleDialog"
      v-if="roleDialog"
      @close="
        roleDialog = false;
        roleData = null;
      "
      :roleData="roleData"
      @addRole="addRole"
      @editRole="editRole"
    ></add-role>
    <power-manage
      :show="powerDialog"
      @close="powerDialog = false"
      :roleId="roleId"
    ></power-manage>
    <menu-manage
      :show="menuDialog"
      @close="menuDialog = false"
      :roleId="roleId"
      @editMenuByRole="editMenuByRole"
    ></menu-manage>
  </el-row>
</template>

<script>
import addRole from "@/components/system/addRole";
import powerManage from "@/components/system/powerManage";
import menuManage from "@/components/system/menuManage";
import TextButton from "@/components/TextButton";

import {
  getRoleByPage,
  addRoles,
  editRole,
  delRole,
  editMenuByRole,
  getSystemList,
} from "@/api/system/role";
import getAccountColor from "@/mixins/getStateColor";

// 默认展示列

const baseColSetting = [
  {
    label: "角色名称",
    prop: "name",
    state: true,
    align: "left",
    width: "150px",
  },
  {
    label: "所属平台",
    prop: "system",
    state: true,
    align: "center",
    width: "150",
  },
  {
    label: "描述",
    prop: "description",
    state: true,
    align: "center",
    width: "150",
  },
  {
    label: "状态",
    prop: "state",
    state: true,
    align: "center",
    width: "150",
  },

  {
    label: "创建时间",
    prop: "createTime",
    state: false,
    sortable: true,
    align: "center",
    width: "150",
  },
  {
    label: "操作",
    state: true,
    align: "center",
    width: "150",
  },
];
export default {
  mixins: [getAccountColor],
  components: {
    addRole,
    menuManage,
    powerManage,
    TextButton,
  },
  data() {
    return {
      inputVal: "",
      select: "",
      roleDialog: false,
      powerDialog: false,
      menuDialog: false,
      dataList: [],
      roleData: null,
      tableKey: 1,
      systemList: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
        layout: "total, sizes, prev, pager, next, jumper",
        total: 0,
      },
      searchForm: {
        name: null,
        state: null,
      },
      options: [
        {
          value: "E",
          label: "启用",
        },
        {
          value: "N",
          label: "停用",
        },
      ],
      roleId: "",
      colSetting: JSON.parse(JSON.stringify(baseColSetting)), // 表格展示列
      baseColSetting: JSON.parse(JSON.stringify(baseColSetting)),
    };
  },
  created() {
    this.getRoleByPage();
    getSystemList({}).then((res) => {
      this.systemList = res.body;
    });
  },
  computed: {
    defaultHeader() {
      return this.colSetting.filter((item) => item.state);
    },
  },
  methods: {
    async getRoleByPage() {
      const res = await getRoleByPage({
        needCount: true,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        ...this.searchForm,
      });
      this.dataList = res.body.list;
      this.pagination.total = res.body.total;
    },

    // 添加角色
    add() {
      this.roleDialog = true;
    },

    // 编辑角色
    edit(row) {
      this.roleDialog = true;
      this.roleData = row;
    },
    update(data) {
      this.tableKey = this.tableKey + 1;
      if (data.lenght !== 0) {
        this.colSetting.forEach((item) => {
          item.state = false;
          data.forEach((ele) => {
            if (ele === item.label && !item.state) {
              item.state = true;
            }
          });
        });
      } else {
        this.colSetting = [];
      }
    },
    // 删除角色
    del(row) {
      this.$confirm("确定删除当前角色吗？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await delRole({
            roleId: row.id,
          });
          window.$msg("删除成功");
          this.getRoleByPage();
        })
        .catch(() => {
          return false;
        });
    },

    memberMan(row) {
      this.roleId = row.id;
      this.powerDialog = true;
    },

    PermissionMan(row) {
      this.PermissionDialog = true;
    },

    menuMan(row) {
      this.roleId = row.id;
      this.menuDialog = true;
    },

    // 分页
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.getRoleByPage();
    },
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.getRoleByPage();
    },
    // 新增角色
    addRole(data) {
      addRoles({
        name: data.name,
        description: data.description,
        state: data.state,
        systemId: data.systemId,
      }).then((res) => {
        if (res.state === "success") {
          this.roleDialog = false;
          let seleData = {
            roleId: res.body.id,
            menuIds: data.menuIds,
          };
          editMenuByRole({
            ...seleData,
          }).then((res) => {
            if (res.state === "success") {
              window.$msg("操作成功");
              this.getRoleByPage();
            }
          });
        }
      });
    },

    // 编辑角色
    async editRole(data) {
      editRole({
        id: data.roleId,
        description: data.description,
        state: data.state,
      }).then((res) => {
        if (res.state === "success") {
          let seleData = {
            roleId: data.roleId,
            menuIds: data.menuIds,
          };
          editMenuByRole({
            ...seleData,
          }).then((res) => {
            if (res.state === "success") {
              window.$msg("操作成功");
               this.roleDialog = false;
               this.roleData = null;
              this.getRoleByPage();
            }
          });
        }
      });
      // editRole({
      //   ...roleData,
      // });
      // window.$msg("编辑成功");
      // this.roleDialog = false;
      // this.roleData = null;
      // this.getRoleByPage();
    },

    editMenuByRole(data) {
      if (!data.menuIds[0]) {
        this.menuDialog = false;
      } else {
        let seleData = {
          roleId: data.roleId,
          menuIds: data.menuIds,
        };
        editMenuByRole({
          ...seleData,
        });
        window.$msg("操作成功");
        this.menuDialog = false;
      }
    },
    /**
     * 重置表单
     */
    resetForm() {
      this.pageInfo.pageNum = 1;
      this.pageInfo.pageSize = 10;
      this.searchForm = {
        name: null,
        state: null,
      };
      this.getRoleByPage();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/container.scss";
</style>
