import $axios from '@/utils/request'


// RTM日志
export function getInteractionLogList(data) {
  const url = '/interactionClass/getInteractionLogList'
  return $axios.fPost(url, data)
}
// 获取全部角色
export function getRolesAll(data) {
    const url = '/role/roles/all'
    return $axios.fPost(url, data)
}
// 获取菜单树
export function getMenuTree(data) {
    const url = '/menu/tree/list'
    return $axios.fGet(url, data)
}
// 获取系统列表
export function getSystemList(data) {
    // private String id;
    // private String systemName;
    // private String createTime;
    // private String systemCode;
    const url = '/perm/system/getList'
    return $axios.fGet(url, data)
}

// 获取角色列表
export function getRoleByPage(data) {
    const url = '/role/getRoleByPage'
    return $axios.fPost(url, data)
}

// 新增角色
export function addRoles(data) {
    const url = '/role/insert'
    return $axios.fPost(url, data)
}

// 编辑角色
export function editRole(data) {
    const url = '/role/update'
    return $axios.fPost(url, data)
}

// 删除角色
export function delRole(data) {
    const url = '/role/delete'
    return $axios.fGet(url, data)
}

// 获取菜单树
export function getMenuList(data) {
    const url = '/menu/tree/list'
    return $axios.fPost(url, data)
}

// 获取角色菜单
export function getMenuByRole(data) {
    const url = '/role/menu/getMenuByRole'
    return $axios.fGet(url, data)
}

// 修改角色菜单'
export function editMenuByRole(data) {
    const url = '/role/menu/updateRoleMenu'
    return $axios.fPost(url, data)
}

// 根据角色获取用户列表
export function getUserByRole(data) {
    const url = '/user/role/getUserByRole'
    return $axios.fPost(url, data)
}

// 添加角色用户
export function addUserRole(data) {
    const url = '/user/role/addUserByRole'
    return $axios.fPost(url, data)
}

// 删除角色用户
export function delUserRole(data) {
    const url = '/user/role/deleteRoleUser'
    return $axios.fPost(url, data)
}
